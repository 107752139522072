<template>
  <div id="projectProposal">
    <el-dialog
      :title="projectProposalFormTitle"
      width="1200px"
      :visible.sync="projectProposalDialogVisible"
      :close-on-click-modal="false"
      @close="projectProposalDialogClose"
    >
      <el-form
        ref="projectProposalFormRef"
        :model="projectProposalForm"
        :rules="projectProposalFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目编号" prop="projectNo">
              <el-input v-model="projectProposalForm.projectNo" placeholder="请输入项目编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目名称" prop="projectName">
              <el-input v-model="projectProposalForm.projectName" placeholder="请输入项目名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目经理" prop="projectManager">
              <el-input v-model="projectProposalForm.projectManager" placeholder="请输入项目经理" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目总预算" prop="totalBudget">
              <el-input v-model="projectProposalForm.totalBudget" placeholder="请输入项目总预算" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目周期" prop="cycle">
              <el-input v-model="projectProposalForm.cycle" placeholder="请输入项目周期" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目组织结构" prop="organizationStructure">
              <el-input
                v-model="projectProposalForm.organizationStructure"
                placeholder="请输入项目组织结构"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目组人员职责" prop="duty">
              <el-input
                v-model="projectProposalForm.duty"
                placeholder="请输入项目组人员职责"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="里程碑确定及激励分配" prop="milestone">
              <el-input
                v-model="projectProposalForm.milestone"
                placeholder="请输入里程碑确定及激励分配"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目概述" prop="overview">
              <el-input
                v-model="projectProposalForm.overview"
                placeholder="请输入项目概述"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目预算" prop="budget">
              <el-input
                v-model="projectProposalForm.budget"
                placeholder="请输入项目预算"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目进度安排" prop="schedule">
              <el-input
                v-model="projectProposalForm.schedule"
                placeholder="请输入项目进度安排"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="小试结果及可行性分析结论" prop="analysisConclusion">
              <el-input
                v-model="projectProposalForm.analysisConclusion"
                placeholder="请输入小试结果及可行性分析结论"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="重要性" prop="leaderImportance">
              <el-input v-model="projectProposalForm.leaderImportance" placeholder="请输入重要性" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="技术难度" prop="leaderTechnicalDifficulty">
              <el-input v-model="projectProposalForm.leaderTechnicalDifficulty" placeholder="请输入技术难度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预算" prop="leaderBudget">
              <el-input v-model="projectProposalForm.leaderBudget" placeholder="请输入预算" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总得分" prop="leaderTotalScore">
              <el-input v-model="projectProposalForm.leaderTotalScore" placeholder="请输入总得分" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="定级" prop="leaderRating">
              <el-input v-model="projectProposalForm.leaderRating" placeholder="请输入定级" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="激励额度" prop="leaderIncentiveAmount">
              <el-input v-model="projectProposalForm.leaderIncentiveAmount" placeholder="请输入激励额度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="签字" prop="leaderSignature">
              <el-input v-model="projectProposalForm.leaderSignature" placeholder="请输入签字" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="leaderSignatureDate">
              <el-date-picker v-model="projectProposalForm.leaderSignatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="projectProposalDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="projectProposalFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="项目名称">
        <el-input v-model="searchForm.projectName" placeholder="请输入项目名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="projectProposalPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="projectNo" label="项目编号" />
      <el-table-column prop="projectName" label="项目名称" />
      <el-table-column prop="projectManager" label="项目经理" />
      <el-table-column prop="totalBudget" label="项目总预算" />
      <el-table-column prop="cycle" label="项目周期" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="projectProposalPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addProjectProposal, deleteProjectProposal, updateProjectProposal, selectProjectProposalInfo, selectProjectProposalList } from '@/api/technology/projectProposal'

export default {
  data () {
    return {
      projectProposalDialogVisible: false,
      projectProposalFormTitle: '',
      projectProposalForm: {
        id: '',
        projectNo: '',
        projectName: '',
        projectManager: '',
        totalBudget: '',
        cycle: '',
        organizationStructure: '',
        duty: '',
        milestone: '',
        overview: '',
        budget: '',
        schedule: '',
        analysisConclusion: '',
        leaderImportance: '',
        leaderTechnicalDifficulty: '',
        leaderBudget: '',
        leaderTotalScore: '',
        leaderRating: '',
        leaderIncentiveAmount: '',
        leaderSignature: '',
        leaderSignatureDate: ''
      },
      projectProposalFormRules: {
        projectNo: [{ required: true, message: '项目编号不能为空', trigger: ['blur', 'change']}]
      },
      projectProposalPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        projectName: ''
      }
    }
  },
  created () {
    selectProjectProposalList(this.searchForm).then(res => {
      this.projectProposalPage = res
    })
  },
  methods: {
    projectProposalDialogClose () {
      this.$refs.projectProposalFormRef.resetFields()
    },
    projectProposalFormSubmit () {
      if (this.projectProposalFormTitle === '项目立项表详情') {
        this.projectProposalDialogVisible = false
        return
      }
      this.$refs.projectProposalFormRef.validate(async valid => {
        if (valid) {
          if (this.projectProposalFormTitle === '新增项目立项表') {
            await addProjectProposal(this.projectProposalForm)
          } else if (this.projectProposalFormTitle === '修改项目立项表') {
            await updateProjectProposal(this.projectProposalForm)
          }
          this.projectProposalPage = await selectProjectProposalList(this.searchForm)
          this.projectProposalDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.projectProposalFormTitle = '新增项目立项表'
      this.projectProposalDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteProjectProposal(row.id)
        if (this.projectProposalPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.projectProposalPage = await selectProjectProposalList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.projectProposalFormTitle = '修改项目立项表'
      this.projectProposalDialogVisible = true
      this.selectProjectProposalInfoById(row.id)
    },
    handleInfo (index, row) {
      this.projectProposalFormTitle = '项目立项表详情'
      this.projectProposalDialogVisible = true
      this.selectProjectProposalInfoById(row.id)
    },
    selectProjectProposalInfoById (id) {
      selectProjectProposalInfo(id).then(res => {
        this.projectProposalForm.id = res.id
        this.projectProposalForm.projectNo = res.projectNo
        this.projectProposalForm.projectName = res.projectName
        this.projectProposalForm.projectManager = res.projectManager
        this.projectProposalForm.totalBudget = res.totalBudget
        this.projectProposalForm.cycle = res.cycle
        this.projectProposalForm.organizationStructure = res.organizationStructure
        this.projectProposalForm.duty = res.duty
        this.projectProposalForm.milestone = res.milestone
        this.projectProposalForm.overview = res.overview
        this.projectProposalForm.budget = res.budget
        this.projectProposalForm.schedule = res.schedule
        this.projectProposalForm.analysisConclusion = res.analysisConclusion
        this.projectProposalForm.leaderImportance = res.leaderImportance
        this.projectProposalForm.leaderTechnicalDifficulty = res.leaderTechnicalDifficulty
        this.projectProposalForm.leaderBudget = res.leaderBudget
        this.projectProposalForm.leaderTotalScore = res.leaderTotalScore
        this.projectProposalForm.leaderRating = res.leaderRating
        this.projectProposalForm.leaderIncentiveAmount = res.leaderIncentiveAmount
        this.projectProposalForm.leaderSignature = res.leaderSignature
        this.projectProposalForm.leaderSignatureDate = res.leaderSignatureDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectProjectProposalList(this.searchForm).then(res => {
        this.projectProposalPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectProjectProposalList(this.searchForm).then(res => {
        this.projectProposalPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectProjectProposalList(this.searchForm).then(res => {
        this.projectProposalPage = res
      })
    }
  }
}
</script>

<style>
</style>
