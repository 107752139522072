import { render, staticRenderFns } from "./ProjectProposal.vue?vue&type=template&id=7aee8837"
import script from "./ProjectProposal.vue?vue&type=script&lang=js"
export * from "./ProjectProposal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports